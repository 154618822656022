<template>
   <v-container class="pt-4">
        <v-row no-gutters>
            <v-col cols="12" class="d-flex">
                <span class="display-1">{{orderInfo.roadtripName}} ({{orderInfo.orderNumber}})</span>
            </v-col>
            <v-col cols="12" class="">{{formatDayMonth(orderInfo.date)}}</v-col>
        </v-row>
        <v-row no-gutters class="blue-info">
            <v-col :cols="colSize">
                <div class="pa-2 mr-2 text-center primary darken-2 text-no-wrap rounded-lg d-flex">
                    <v-img :src="participantIcon" contain></v-img>
                    <span class="icon-text">{{orderInfo.participantCount}}</span>
                </div>
            </v-col>
            <v-col :cols="colSize" v-if="orderInfo.offersLunch">
                <div class="pa-2 mx-2 text-center primary darken-2 text-no-wrap rounded-lg d-flex">
                    <v-img :src="lunchIcon" contain></v-img>
                    <span class="icon-text">{{orderInfo.lunchCount}}</span>
                </div>
            </v-col>
            <v-col :cols="colSize" v-if="orderInfo.offersDinner">
                <div class="pa-2 ml-2 text-center primary darken-2 text-no-wrap rounded-lg d-flex">
                    <v-img :src="dinnerIcon" contain></v-img>
                    <span class="icon-text">{{orderInfo.dinnerCount}}</span>
                </div>
            </v-col>
        </v-row>

        <v-row no-gutters>
            <v-col cols="12" class="header px-0 pt-4 pb-1">
                <span class="display-1">Notities</span>
            </v-col>
        </v-row>
        <v-row no-gutters>
            <v-col cols="12" v-if="hasNote">
                <div>{{orderInfo.note}}</div>
            </v-col>
            <v-col cols="12" v-if="!hasNote">
                <div>-</div>
            </v-col>
        </v-row>

        <v-row no-gutters>
            <v-col cols="12" class="header px-0 pt-4 pb-1">
                <span class="display-1">Persoonsgegevens</span>
            </v-col>
        </v-row>
        <v-row no-gutters>
            <v-col cols="6" class="ma-auto px-0">
                Voornaam
            </v-col>
            <v-col cols="6" class="ma-auto px-0">
                <div>{{orderInfo.accountOwnerBasePersonInfo.firstname}}</div>
            </v-col>
        </v-row>
        <v-row no-gutters>
            <v-col cols="6" class="ma-auto px-0">
                Achternaam
            </v-col>
            <v-col cols="6" class="ma-auto px-0">
                <div>{{orderInfo.accountOwnerBasePersonInfo.lastName}}</div>
            </v-col>
        </v-row>
        <v-row no-gutters> 
            <v-col cols="6" class="ma-auto px-0">
                Geboortedatum
            </v-col>
            <v-col cols="6" class="ma-auto px-0">
                <div>{{formatDateWithoutTime(orderInfo.accountOwnerBasePersonInfo.dateOfBirth)}}</div>
            </v-col>
        </v-row>
        <v-row no-gutters class="pb-1">
            <v-col cols="6" class="ma-auto px-0">
                Email
            </v-col>
            <v-col cols="6" class="ma-auto px-0">
                <div>
                    <strong class="link">
                        <a target="_blank"
                            :href="`mailto:${orderInfo.emailAddress}`">
                            {{orderInfo.emailAddress}}
                        </a>
                    </strong>
                </div>
            </v-col>
        </v-row>
        <v-row no-gutters class="pb-1">
            <v-col cols="6" class="ma-auto px-0">
                Telefoonnummer
            </v-col>
            <v-col cols="6" class="ma-auto px-0">
                <div>
                    <strong class="link">
                        <a :href="`tel:${orderInfo.accountOwnerBasePersonInfo.phoneNumber}`">
                            {{orderInfo.accountOwnerBasePersonInfo.phoneNumber}}
                        </a>
                    </strong>
                </div>
            </v-col>
        </v-row>

        <v-row no-gutters>
            <v-col cols="12" class="header px-0 pt-4 pb-1">
                <span class="display-1">Adresgegevens</span>
            </v-col>
        </v-row>
        <v-row no-gutters>
            <v-col cols="6" class="ma-auto px-0">
                Straat
            </v-col>
            <v-col cols="6" class="ma-auto px-0">
                <div>{{orderInfo.address.street}}</div>
            </v-col>
        </v-row>
        <v-row no-gutters>
            <v-col cols="6" class="ma-auto px-0">
                Huisnummer
            </v-col>
            <v-col cols="6" class="ma-auto px-0">
                <div>{{orderInfo.address.houseNumber}}</div>
            </v-col>
        </v-row>
        <v-row no-gutters>
            <v-col cols="6" class="ma-auto px-0">
                Postcode
            </v-col>
            <v-col cols="6" class="ma-auto px-0">
                <div>{{orderInfo.address.zipCode}}</div>
            </v-col>
        </v-row>
        <v-row no-gutters>
            <v-col cols="6" class="ma-auto px-0">
                Plaatsnaam
            </v-col>
            <v-col cols="6" class="ma-auto px-0">
                <div>{{orderInfo.address.city}}</div>
            </v-col>
        </v-row>
        <v-row no-gutters>
            <v-col cols="6" class="ma-auto px-0">
                Land
            </v-col>
            <v-col cols="6" class="ma-auto px-0">
                <div>{{getCountryName(orderInfo.address.country)}}</div>
            </v-col>
        </v-row>
        <v-row no-gutters>
            <v-col cols="6" class="ma-auto px-0">
                Bedrijf
            </v-col>
            <v-col cols="6" class="ma-auto px-0">
                <div>{{orderInfo.address.company}}</div>
            </v-col>
        </v-row>

        <v-row no-gutters>
            <v-col cols="12" class="header pt-4 pb-1">
                <span class="display-1">Allergieën</span>
            </v-col>
            <v-col cols="12">
                <div>
                    Allergieën en voorkeuren: <span class="text-lowercase">{{getFoodRestrictions(orderInfo.accountOwnerBasePersonInfo.foodRestrictions)}}</span>
                </div>
            </v-col>
        </v-row>
            
            <!-- Voertuig -->
        <div>
            <v-row no-gutters>
                <v-col cols="12" class="header px-0 pt-4 pb-1">
                    <span class="display-1">Voertuig</span>
                </v-col>
            </v-row>
            <v-row no-gutters>
                <v-col cols="12" class="pb-3 pt-1">
                    <div> Het volgende voertuig staat geregistreerd voor deelname aan de {{orderInfo.roadtripName}}:</div>
                </v-col>
                <v-col cols="12">
                    <v-divider color="white"></v-divider>
                </v-col>
                <v-col cols="12" class="header px-0 ma-0">
                    <v-list-item class="px-0 pt-1">
                        <v-list-item-avatar width="60" height="60">
                            <div class="pa-7 rounded-circle d-inline-block initials-avatar text-uppercase font-weight-black">{{getVehicleMake(orderInfo.vehicle.make).charAt(0)}}</div>
                        </v-list-item-avatar>
                        <v-list-item-content>
                            <v-list-item-title v-text="`${getVehicleMake(orderInfo.vehicle.make)} ${orderInfo.vehicle.model} (${orderInfo.vehicle.year})`" class="subtitle-1 pb-2"></v-list-item-title>
                            <div> {{ `${orderInfo.vehicle.engineCapacity} | ${orderInfo.vehicle.cylinders} cilinder | ${getFuelType(orderInfo.vehicle.fuelType).replace('_', ' ')} | ${orderInfo.vehicle.power} pk`}} </div>
                        </v-list-item-content>
                    </v-list-item>
                </v-col>
                <v-col cols="12">
                    <div class="pb-3">{{`Kenteken: ${orderInfo.vehicle.licensePlate}`}} | Meegereden: {{orderInfo.vehicle.participatedDistance}} km</div>
                </v-col>
            </v-row>
            <v-divider color="white"></v-divider>
        </div>

        <!-- Passagiers -->
        <v-row no-gutters>
            <v-col cols="12" class="header px-0 pt-4 pb-1">
                <span class="display-1">Passagiers</span>
            </v-col>
        </v-row>
        <div class="pb-3">
            <v-row no-gutters>
            <v-col cols="12" class="pb-3 pt-1">
                <div>{{ orderInfo.passengers.length == 0 
                    ? `Geen passagiers.`
                    : `De volgende passagiers rijden mee.`}}
                </div>
            </v-col>
            <v-col cols="12">
                <v-divider color="white"></v-divider>
            </v-col>
            </v-row>
            <template v-for="(item, index) in orderInfo.passengers">
                <v-row no-gutters  :key="index">
                    <v-col cols="12" class="header px-0 ma-0">
                        <v-list-item class="px-0 pt-1">
                            <v-list-item-avatar width="60" height="60">
                                <div class="pa-7 rounded-circle d-inline-block initials-avatar text-uppercase font-weight-black" @click="setActiveItem()">{{getPersonInitials(item.firstname, item.lastName)}}</div>
                            </v-list-item-avatar>
                            <v-list-item-content class="pb-0">
                                <v-list-item-title class="subtitle-1 pb-2">{{fullname(item)}} ({{getDateOnly(item.dateOfBirth)}})</v-list-item-title>
                                <div class="d-flex">
                                    <div class="pt-1 pr-3">{{item.phoneNumber}}</div>
                                </div>
                            </v-list-item-content>
                        </v-list-item>
                    </v-col>
                    <v-col cols="12">
                        <div class="pb-3">
                            Allergieën en voorkeuren: <span class="text-lowercase">{{getFoodRestrictions(item.foodRestrictions)}}</span>
                        </div>
                    </v-col>
                    <v-col cols="12">
                        <v-divider color="white"></v-divider>
                    </v-col>
                </v-row>
            </template>
        </div>

        <v-footer
            v-bind="localAttrs"
            :padless="true"
            class="checkin-footer" >
            <div class="bottom-section">
                <v-container class="first-container">
                    <v-row>
                        <v-col class="pt-0">
                            <v-btn color="primary" block v-if="!orderInfo.isCheckedIn"
                                @click="checkInUser()">
                                Check in
                            </v-btn>
                            <v-btn color="primary" block v-if="orderInfo.isCheckedIn"
                                @click="checkOutUser()">
                                Check out
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-container>
            </div>
        </v-footer>
    </v-container>
</template>

<script>
import DataMixin from "@/mixins/data.mixin";
import RouteMixin from "@/mixins/route.mixin";
import FormatMixin from "@/mixins/format.mixin";
import MenuHelperMixin from "@/mixins/menu-helper.mixin";

export default {
    name: 'CheckinAuthorized',
    props: {
        orderInfo: Object
    },
    computed: {
        localAttrs () {
            const attrs = {
                fixed: true
            }
            
            return attrs;
        },
        hasNote()
        {
            return this.orderInfo.note != null;
        },
        colSize()
        {
            return this.orderInfo.offersLunch && this.orderInfo.offersDinner ? 4 : 6;
        },
    },
    mixins: [DataMixin, RouteMixin, FormatMixin, MenuHelperMixin],
    data: () => ({
        participantIcon: require('@/assets/icons/participant.svg'),
        lunchIcon: require('@/assets/icons/lunch.svg'),
        dinnerIcon: require('@/assets/icons/dinner.svg'),
    }),
    methods: {
        fullname(item)
        {
            if(!item)
            {
                return "";
            }

            return `${item.firstname} ${item.lastName}`;
        },
        checkInUser()
        {
            this.$emit('checkInUser');
        },
        checkOutUser()
        {
            this.$emit('checkOutUser');
        }
    }
}
</script>
<style scoped>
.v-application a
{
    color: #76baff;
}

.blue-info .v-image 
{
    width: 40px;
    height: 40px;
    max-width: 50%;
    margin-top: 10px;
}

.blue-info .v-image .v-image__image
{
    background-position: center right;
}

.blue-info {
    font-size: 40px;
    margin-top: 20px;
}

.blue-info .icon-text
{
    padding-left: 10px;
}

.initials-avatar
{
    background: white;
    color: #181717;
    font-size: 22px;
}

@media (min-width: 1264px)
{
    .bottom-section .first-container
    {
        max-width: 1080px !important;
        padding: 12px 20px;
    }
}

@media (min-width: 960px)
{
    .bottom-section .first-container
    {
        max-width: 900px;
    }
}

.bottom-section {
    background: #181717;
}

.header {
    margin: 0px !important;
}
</style>
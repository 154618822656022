<template>
   <BannerTemplate :bannerSource="orderInfo.mainProductPhotoURL">
        <div class="px-2 mt-n3 unauthorized-content">
            <v-container class="pt-0">
                <v-row no-gutters>
                    <v-col cols="12" class="d-flex">
                        <span class="display-1">{{orderInfo.roadtripName}}</span>
                        <v-spacer />
                        <span class="body-1 tag-wrap">
                            <v-alert class="tag mx-3 my-1 pa-0 text-center red" 
                                :class="`${orderInfo.labelColor}`"
                                text--white
                                width="100"
                                dense
                                prominent
                                text>{{orderInfo.label}}</v-alert>
                        </span>
                    </v-col>
                </v-row>
                <v-row no-gutters>
                    <v-col cols="12">
                        {{orderInfo.message}}
                    </v-col>
                </v-row>
                <v-row no-gutters>
                    <v-col class="py-4">
                        <v-btn color="primary" block @click.stop="goto(orderInfo.galleryButtonURL)">
                            {{ orderInfo.galleryButtonText }}
                        </v-btn>
                    </v-col>
                </v-row>
            </v-container>
        </div>
    </BannerTemplate>
</template>

<script>
import RouteMixin from "@/mixins/route.mixin";
import BannerTemplate from '@/components/common/BannerTemplate';

export default {
    name: 'CheckinUnauthorized',
    components: {
        BannerTemplate
    },
    props: {
        orderInfo: Object
    },
    mixins: [RouteMixin],
    data: () => ({
     
    }),
}
</script>
<style scoped>
.unauthorized-content .tag-wrap 
{
    margin-top: -65px;
    margin-right: -10px;
}
</style>

<template>
    <v-container class="pa-0">
        <v-row no-gutters>
          <v-col>
            <ImageDiv :imageSource="bannerSource" :templateClass="'bannerImg'" />
          </v-col>
        </v-row>
        <div class="px-2">
            <v-container>
              <v-row no-gutters>
                  <v-col>
                      <p v-if="title" class="display-1 mt-1 mb-2 break-word">{{title}}</p>
                      <div v-html="intro"></div>
                  </v-col>
              </v-row>
            </v-container>
        </div>
          <!-- Here goes the inserted view -->
          <slot></slot>
    </v-container>
</template>

<script>
import ImageDiv from '@/components/common/ImageDiv';

export default {
  name: 'BannerTemplate',
      props: {
          bannerSource: String,
          title: String,
          intro: String,
      },
  components: {
      ImageDiv
  },
  data: () => ({
  }),
  methods: {
  }
}
</script>
<style>

</style>

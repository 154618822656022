<template>
    <div>
        <LoadingIcon :isLoading="isLoading" />
        <div v-show="!isLoading">

            <div v-if="isAdmin" class="authorized-scan px-3">
                <CheckinAuthorized :orderInfo="orderInfo" @checkInUser="checkInOrOutUser(true)" @checkOutUser="checkInOrOutUser(false)" />
            </div>

            <!-- Unauthorized -->
            <CheckinUnauthorized :orderInfo="orderInfo" v-if="!isAdmin" />
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import LoadingIcon from '@/components/common/LoadingIcon';
import CheckinAuthorized from '@/components/roadtrip/tripday/CheckinAuthorized';
import CheckinUnauthorized from '@/components/roadtrip/tripday/CheckinUnauthorized';
import DataMixin from "@/mixins/data.mixin";
import RouteMixin from "@/mixins/route.mixin";
import FormatMixin from "@/mixins/format.mixin";
import MenuHelperMixin from "@/mixins/menu-helper.mixin";
import RoadtripMixin from "@/mixins/roadtrip.mixin";

export default {
    name: 'CheckIn',
    components: {
        LoadingIcon,
        CheckinAuthorized,
        CheckinUnauthorized
    },
    data: () => ({
        orderId: '',
        isLoading: true,
        orderInfo: {
            accountOwnerBasePersonInfo: {
                foodRestrictions: []
            },
            vehicle: {
                fuelType: 0
            },
            passengers: [],
            address: {}
        }
    }),
    mixins: [DataMixin, RouteMixin, FormatMixin, MenuHelperMixin, RoadtripMixin],
    computed: {
        ...mapState('authorizedModule', ['isAdmin']),
    },
    beforeCreate(){
        this.$store.dispatch('menuHelper/getRoadtripLabels');
    },
    mounted () 
    {
        this.$store.commit('setFooterVisibility', !this.isAdmin);
    },
    watch: {
        isAdmin: {
            immediate: true,
            handler() {
                this.orderId = this.$route.params.orderId;
                this.getOrderInformation();
            }
        }
    },
    methods: {
        getOrderInformation()
        {
            if (this.isAdmin){
                this.getScanQRInfo();
            }
            else
            {
                this.getUnauthorizedScanQRInfo();        
            }
        },
        getScanQRInfo()
        {
            this.isLoading = true;
            this.$store.dispatch('roadtripModule/scanQR', this.orderId)
                .then(data => {
                    this.orderInfo = data;
                    this.orderInfo.date = this.getDateOnly(data.roadtripDate);
                    this.isLoading = false;
            })
            .catch(() => { this.isLoading = false; });
        },
        getUnauthorizedScanQRInfo()
        {
            this.isLoading = true;
            this.$store.dispatch('roadtripModule/getUnauthorizedScanQRInfo', this.orderId)
                .then(data => {
                    this.orderInfo = this.mapRoadtripData(data);
                    this.isLoading = false;
            })
            .catch(() => { this.isLoading = false; });
        },
        checkInOrOutUser(isPresent) 
        {
            //updateCheckInStatus
            this.isLoading = true;
            this.$store.dispatch('roadtripModule/updateCheckInStatus', {
                orderId: this.orderId,
                isPresent: isPresent
            })
                .then(() => {
                    this.isLoading = false; 
                    this.goToTripStatistics(this.orderInfo.roadtripId);
            })
            .catch(() => { 
                this.$store.commit('dialog/showDialog', "Er is iets misgegaan tijdens het inchecken.");
                this.isLoading = false; 
            });
        },
    }
}
</script>
<style scoped>
.authorized-scan {
    padding-bottom: 70px;
}
</style>